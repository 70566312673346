.app-logo {
  height: 20vmin;
  pointer-events: none;
  margin: 0 0 5vmin;
}

.app-store-logo {
  width: 200px;
  margin: 10vmin auto 0;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.paf-tour {
  text-align: center;
  padding: 5vmin;
}

.paf-tour:after {
  content: '';
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  background-color: #ddfdf7;
  height: 75%;
}

.paf-tour ul {
  margin: 5vmin 0 0;
  transition: all .4s ease;
}

.paf-tour ul li {
  display: block;
  margin: 4vmin 0;
}

.paf-tour ul li button {
  background: #eee;
  border-radius: 5px;
  padding: 1vmin;
  color: #666;
}

.paf-tour ul li .tour-btn {
  color: #203d3f;
  font-weight: bold;
  background: none;
  font-size: 3vmin;
}

.paf-tour ul li .send-btn {
  background: #06e6c8;
  color: #fff;
  font-weight: bold;
  font-size: 2.5vmin;
  padding: 1.5vmin 2vmin;
}

.paf-tour-container-holder {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255,.8);
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.paf-tour-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ddfdf7;
  z-index: 9999;
  width: 60vw;
  min-height: 50vmin;
  padding: 5vmin;
  border-radius: 6px;
  box-shadow: 8px 12px 12px rgba(100,100,100,.1);
}

.paf-tour-container h3 {
  margin: 0 0 4vmin;
  font-size: 3.5vmin;
}

.paf-tour-container h3 span {
  border-bottom: 2px solid var(--primary-color);
}

.paf-tour-container img {
  margin: 2vmin auto 0;
  max-width: 80%;
  height: 25vh;
}

.paf-tour-container p {
  padding: 2vmin 5vmin;
  max-width: 80%;
  margin: 0 auto;
  font-size: 2.2vmin;
}

.paf-tour-container-buttons {
  display: flex;
  flex-direction: column;
  margin: 4vmin auto 0;
  max-width: 300px;
}

.main-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.paf-tour-container-buttons .main-buttons button {
  width: 42%;
  margin: 0 4%;
}

.paf-tour-container-buttons button {
  flex: 1;
  width: 100%;
  margin: 2vmin 0;
  font-size: 2.5vmin;
  padding: 2vmin;
}

@media screen and (max-width: 678px) and (orientation: portrait) {
  .app-store-logo {
    width: 125px;
  }

  .paf-tour ul {
    margin: 8vmin 0;
  }

  .paf-tour ul li {
    margin: 6vmin 0;
  }

  .paf-tour ul li button {
    padding: 2vmin;
  }
  
  .paf-tour-container h3 {
    margin: 0 0 6vmin;
  }
}