.paf-feed-header {
  display: flex;
  padding: 1vmin 0;
  align-items: center;
  margin: 0 0 4vmin;
}

.paf-feed-header img {
  height: 6vmin;
}

.paf-feed-header h2 {
  margin: 0 0 0 2vmin;
}

.paf-feed-header .welcome-btn {
  background: #ddfdf7;
  padding: 1vmin;
  border-radius: 6px;
  margin: 0 4vmin 0 auto;
  flex: initial;
}

.paf-user {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--darkest-gray-color);
  font-style: normal;
  font-weight: bold;
}

.paf-username {
  padding: 0 1vmin 0 0;
}

.paf-feed-header button {
  margin: 0;
}

.paf-feed-menu {
  margin: 0 0 4vmin;
  display: flex;
}

.paf-feed-menu li {
  font-size: 2.2vmin;
  line-height: 3vmin;
  padding: 0.5vmin 2vmin;
  border-radius: 15px;
  margin: 0 2vmin 0 0;
  color: #999;
}

.paf-feed-menu li.search {
  margin: 0 0 0 auto;
  color: #111;
}

.paf-feed-menu li.selected {
  background: var(--primary-color);
  color: #111;
}

.paf-feed-menu li:not(.selected):hover {
  cursor: pointer;
  color: #333;
}

.paf-gifts li {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 1vmin !important;
  align-items: center;
  gap: 1vmin;
  word-break: normal;
  margin: 0 !important;
}

.paf-gifts li:not(.header):hover {
  cursor: pointer;
  background-color: #fafafa;
}

.paf-gifts li .flex {
  flex: 1;
}

.paf-gifts li .small {
  flex: 0.5;
}

.paf-gifts li.header {
  background-color: #eee;
  font-weight: bold;
  border-radius: 6px;
}

.paf-gifts li.test span {
  background-color: rgb(162, 218, 250);
  border-radius: 6px;
  font-size: 1.25vmin;
  padding: 1px 2px;
  display: inline-block;
  color: #333;
  margin: 0 0 0 2px;
}

.selected-transaction-row {
  display: flex;
  margin: 0 0 4vmin;
}

.selected-transaction-row h3 {
  flex: initial;
  width: 50%;
}

.selected-transaction-row h4 {
  flex: initial;
  width: 5%;
}

.selected-transaction-row .paf-list {
  margin: 0 0 0 2vmin;
  flex: 1;
}

.selected-transaction-row .paf-list li {
  margin: 0 !important;
  padding: 0 !important;
}

.paf-list, .paf-gifts {
  margin: 4vmin 0;
}

.paf-list li {
  margin: 0 0 2vmin;
  border-bottom: 1px solid #eee;
  padding: 0 0 2vmin;
}

.paf-list li.inline-adder {
  display: flex;
  align-items: center;
}

.inline-inputs {
  flex: initial;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.inline-group {
  flex: initial;
  width: 50%;
}

.inline-group input {
  width: 95%;
  margin: 2vmin 0;
  background: #eee;
  border-radius: 5px;
  padding: 1vmin;
  font-family: inherit;
  font-size: 1.75vmin;
  color: #111;
  border: none;
}

.inline-buttons {
  flex: initial;
  width: 30%;
  display: flex;
}

.inline-buttons button {
  flex: 1;
  margin: 0 1vmin !important;
}

.paf-list li:last-child {
  border: none;
}

.paf-list li img {
  width: 5vmin;
  height: 5vmin;
  border-radius: 100%;
  margin: 0 2vmin 0 0;
}

.paf-list li h3 i {
  font-style: normal;
  font-weight: bold;
  color: var(--darkest-gray-color);
}

.paf-list li h4 {
  margin: 0 0 0 auto;
  color: var(--dark-gray-color);
  font-weight: bold;
}

.paf-list li button {
  margin: 0 0 0 auto;
  color: #999;
  font-weight: bold;
}

.paf-avatar {
  width: 5vmin;
  height: 5vmin;
  border-radius: 100%;
  margin: 0 2vmin 0 0;
  background: #ddd;
  text-align: center;
  text-transform: lowercase;
  font-weight: bold;
  line-height: 5vmin;
  color: var(--dark-gray-color);
}

.pending-buttons {
  margin: 0 0 0 auto;
}

.accept-btn {
  background: var(--primary-color);
  padding: 1vmin;
  border-radius: 4px;
  color: #111 !important;
  margin: 0 0 0 4vmin !important;
}

.reject-btn {
  color: rgb(238, 4, 102) !important;
}


@media screen and (max-width: 678px) and (orientation: portrait) {
  .paf-feed {
    padding: 4vmin;
  }

  .paf-feed-menu {
    margin: 0 0 6vmin;
  }

  .paf-feed-menu li {
    font-size: 3.5vmin;
    line-height: 4vmin;
    padding: 1vmin 2vmin;
  }

  .paf-list li {
    margin: 0 0 4vmin;
    padding: 0 0 4vmin;
  }

  .paf-avatar, .paf-list li img {
    width: 8vmin;
    height: 8vmin;
    line-height: 8vmin;
    margin: 0 4vmin 0 0;
  }
}

.success-toast {
  background-color: aquamarine;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 2vmin;
  border-radius: 6px;
  text-align: center;
}

.success-toast button {
  background-color: rgb(0, 94, 255);
  color: #fff;
  padding: 1vmin 2vmin;
  border-radius: 6px;
  margin: 2vmin 0 0;
}