.paf-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.9);
  z-index: 9999;
}

.paf-spinner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}

.paf-spinner-content h3 {
  font-size: 4vmin;
  color: #ccc;
  animation: glow 3s infinite ease;
}

@keyframes glow {
  0% {
    color: #ccc;
  }
  50% {
    color: #333;
  }
  100% {
    color: #ccc;
  }
}