.onboarding-container-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(1,1,1,.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.onboarding-container {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  width: 40vw;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border: 1px solid #eee;
  box-shadow: 8px 12px 18px rgba(1,1,1,.1);
  border-radius: 6px;
}

.onboarding-container-header {
  background-color: var(--primary-color);
  padding: 1vmin 2vmin;
  display: flex;
  align-items: center;
}

.onboarding-container-header h2 {
  font-size: 2.2vmin;
  font-weight: bold;
}

.onboarding-container-header .close-btn {
  margin: 0 0 0 auto;
}

.onboarding-container-content {
  padding: 2vmin;
  color: #333;
  font-size: 2vmin;
  line-height: 2.5vmin;
}

.onboarding-container-content p {
  margin: 2vmin 0;
}

.onboarding-container .next-btn {
  background-color: #6766ff;
  color: #fff;
  border-radius: 6px;
  padding: 1vmin 2vmin;
  margin: 4vmin 0 0 auto;
  display: block;
}