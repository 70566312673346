@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('/src/icons.css');

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.75vmin;
  line-height: 3vmin;
}

:root {
  --primary-color: #2df6d9;
  --primary-color-text: #17e2c4;
  --gray-color: #ccc;
  --dark-gray-color: #999;
  --darkest-gray-color: #555;
}

h1, h2, h3, h4, h5 {
  font-size: 2vmin;
  line-height: 2.2vmin;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 2.5vmin;
  line-height: 3vmin;
}

ul {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
}

a {
  text-decoration: none;
  transition: all .3s ease;
  display: inline-block;
}

a:hover {
  cursor: pointer;
  opacity: 0.8;
  transform: translateY(-1px);
}

button {
  margin: 0;
  padding: 0;
  background: transparent;
  outline: none;
  transition: all .3s ease;
  border: none;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  font-size: 2vmin;
  line-height: 2.5vmin;
}

button:hover {
  cursor: pointer;
  opacity: 0.8;
  transform: translateY(-1px);
}

button:disabled {
  pointer-events: none;
  opacity: .3;
}

.primary-btn {
  background: var(--primary-color);
  color: #111 !important;
  border-radius: 4px;
  padding: 1vmin;
}

.primary-text-btn {
  color: var(--primary-color-text) !important;
}

.blue-text-btn {
  color: #208df3 !important;
  display: inline-block !important;
  font-weight: bold !important;
  margin: 0 !important;
  padding: 0 !important;
  border-bottom: 2px solid #208df3;
}

.secondary-btn {
  border: 1px solid var(--gray-color);
  color: var(--dark-gray-color);
  border-radius: 4px;
  padding: 1vmin;
}

.cancel-btn {
  padding: 1vmin;
  color: var(--darkest-gray-color);
}

.close-btn {
  font-size: 4vmin;
  line-height: 4vmin;
  color: #333;
}

.paf-form {
  margin: 2vmin auto;
  max-width: 500px;
}

.paf-form.full {
  max-width: none;
}

.paf-form h3 {
  margin: 0 0 4vmin;
}

.paf-form input {
  display: block;
  margin: 2vmin 0;
  width: 100%;
  background: #eee;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 1vmin;
  font-family: inherit;
  font-size: 2vmin;
}

.paf-form button {
  margin: 4vmin 0 2vmin auto;
  display: block;
}

.paf-error {
  color: rgb(241, 19, 48);
}

@media screen and (max-width: 678px) and (orientation: portrait) {
  html, body {
    font-size: 3vmin;
    line-height: 5vmin;
  }

  h1, h2, h3, h4, h5 {
    font-size: 3.5vmin;
    line-height: 4vmin;
  }
  
  h2 {
    font-size: 4vmin;
    line-height: 4.5vmin;
  }

  h3 {
    font-size: 3.5vmin;
    line-height: 4vmin;
  }

  button {
    font-size: 4vmin;
    line-height: 4.5vmin;
  }

  .paf-form input {
    margin: 4vmin 0;
    padding: 2vmin;
    font-size: 4vmin;
  }
}