.paf-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(1,1,1,.4);
  width: 100%;
  height: 100%;
  z-index: 8888;
}

.paf-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  min-height: 20vh;
  transform: translate(-50%,-50%);
  background: #fff;
  border-radius: 4px;
  box-shadow: 6px 9px 12px rgba(1,1,1,.1);
  padding: 3vmin 4vmin;
  overflow: auto;
}

.paf-modal-content.min-height {
  min-height: 80vh;
  max-height: 80vh;
}

.paf-modal-header {
  display: flex;
  margin: 0 0 2vmin;
}

.paf-modal-header h2 {
  flex: 1;
}

.paf-modal-content .close-btn {
  margin: 0 0 0 auto;
  flex: initial;
}

@media screen and (max-width: 678px) and (orientation: portrait) {
  .paf-modal-content {
    top: 5vmin;
    width: 90%;
    padding: 5vmin 7vmin;
    max-height: 65vh;
    transform: translateX(-50%);
  }

  .paf-modal-header {
    margin: 0 0 5vmin;
  }
}