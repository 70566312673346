.settings-content {
  height: 68vh;
  display: flex;
  flex-direction: column;
}

.settings-menu {
  display: flex;
  flex-direction: row;
  margin: 0 0 6vmin;
}

.settings-menu li {
  margin: 0 2vmin 0 0;
  border-bottom: 2px solid #eee;
  padding: 0 0 1vmin;
  font-size: 2vmin;
  font-weight: 500;
  color: #999;
}

.settings-menu li.selected {
  color: #222;
  border-color: var(--primary-color);
}

.settings-menu li:not(.selected):hover {
  cursor: pointer;
  opacity: .8;
}

.main-profile {
  display: flex;
}

.image-section {
  flex: initial; 
  width: 25%;
  text-align: center;
}

.info-section {
  flex: initial;
  width: 75%;
  padding: 0 0 0 4vmin;
}

.bottom-section {
  margin: auto 0 0 0;
  padding: 2vmin 0 0;
  border-top: 1px solid #ddd;
  display: flex;
}

.form-group {
  display: flex;
  margin: 0 0 2vmin;
  align-items: center;
}

.form-group h3 {
  width: 20%;
  flex: initial;
}

.form-group input {
  background: #eee;
  border: none;
  font-family: inherit;
  font-size: 1.75vmin;
  border-radius: 5px;
  padding: 1vmin;
}

.form-group input:disabled {
  pointer-events: none;
  background: none;
}

.edit-btn, .cancel-changes-btn  {
  margin: 0 0 0 auto;
}

.save-btn {
  margin: 0 0 0 2vmin;
}

.upload-btns {
  display: flex;
  margin: 2vmin 0 0;
  align-items: center;
  justify-content: space-between;
}

.profile-image  {
  width: 10vmin;
  height: 10vmin;
  border-radius: 100%;
  background-color: #ddd;
  color: var(--dark-gray-color);
  text-align: center;
  font-size: 3vmin;
  text-align: center;
  line-height: 10vmin;
  text-transform: lowercase;
  font-weight: bold;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  margin: 0 auto;
}

.profile-image label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.profile-image label span {
  background: #111;
  font-size: 1.25vmin;
  line-height: 1.25vmin;
  border-radius: 5px;
  padding: 1vmin;
  color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.profile-image:hover {
  cursor: pointer;
  opacity: .8;
}

.profile-image:hover label {
  visibility: visible;
  cursor: pointer;
}

.photo-upload-input label {
  background: var(--primary-color);
  font-size: 2vmin;
  padding: 0.5vmin 2vmin;
  border-radius: 5px;
  margin: 2vmin 0 0;
  display: inline-block;
}

.photo-upload-input label:hover {
  cursor: pointer;
  opacity: .8;
}

.photo-upload-input input {
  width: 0;
  height: 0;
  display: none;
}

.photo-upload-input i {
  font-size: 1.4vmin;
  display: block;
  margin: 1vmin 0 0;
}

.kids-tab {
  margin: 0;
  padding: 0;
  max-height: 80vh;
  overflow: auto;
}

.kids-tab .paf-list {
  padding: 0;
  margin: 0;
}