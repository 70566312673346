.send-gift-view {
  text-align: center;
}

.send-gift-view iframe {
  width: 100%;
  height: 100%;
  overflow: auto;
  border: none;
  min-height: 60vh;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 4px 8px 12px rgba(0,0,0,.1);
}

.send-gift-view .user-item {
  flex-direction: column;
}

.send-gift-view .paf-list {
  margin: 4vmin 0 0;
}

.send-gift-view .paf-list li img {
  height: 7vmin;
  width: 7vmin;
  margin: 0;
}

.gift-section {
  margin: 0 0 4vmin;
}

.gift-section h4 {
  margin: 0 0 1vmin;
}

.gift-section select, .gift-section textarea {
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-family: inherit;
  font-size: 1.75vmin;
  padding: 1vmin 2vmin;
}

.gift-section textarea {
  width: 60%;
  min-height: 15vh;
}

.gift-section ul {
  display: flex;
  flex-wrap: wrap;
  gap: 2vmin;
  margin: 4vmin 0;
}

.gift-section ul li {
  width: 23%;
  border: 3px solid #eee;
  border-radius: 6px;
  padding: 2vmin;
}

.gift-section ul li.selected {
  border-color: pink;
}

.gift-section ul li:hover {
  cursor: pointer;
  opacity: .8;
}

.gift-section ul li img {
  display: block;
  width: 40%;
  margin: 0 auto 2vmin;
}

.amount-section {
  text-align: left;
  margin: 2vmin 0;
  padding: 2vmin 0;
  display: flex;
  border-bottom: 1px solid #eee;
  gap: 2vmin;
  align-items: center;
}

.amount-section.last {
  border: none;
}

.amount-section h4 {
  font-size: 2vmin;
  flex: initial;
  width: 40%;
}

.amount-section h5, .amount-section input {
  font-size: 2vmin;
  font-weight: bold;
  flex: 1;
  text-align: right;
}

.amount-section input {
  padding: 1vmin;
}

.amount-section h6 {
  font-size: 1.75vmin;
  line-height: 2vmin;
  text-align: center;
  font-weight: 400;
}