.user-item {
  display: flex;
}

.user-item.indent {
  padding: 4vmin 0 0 8vmin;
}

.paf-photo {
  position: relative;
}

.paf-photo label {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  visibility: hidden;
}

.paf-photo label span {
  background: #111;
  font-size: 1vmin;
  line-height: 5vmin;
  border-radius: 5px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 5vmin;
  height: 5vmin;
  border-radius: 100%;
  text-align: center;
}

.paf-photo:hover label {
  visibility: visible;
  cursor: pointer;
}